import React, { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'

function Certificate () {
  const [user, ] = useContext(UserContext)

  if (user.certificate && user.name) {
    return (
      <div className='diploma'>
        <div className='diploma_holder'>
          <h1>Certificate of Completion</h1>
          <strong className="subtitle">HEREBY CERTIFIES THAT</strong>
          <div className="name_block">
            <div className="name_block_holder">
              <p>{user.name} {user.surname}</p>
            </div>
            <div className="bg-holder">
              <p>CloudAMQP</p>
            </div>
          </div>
          <div className="theme-block">
            <strong className="subtitle">COMPLETED THE</strong>
            <h2>RabbitMQ Training Course - by CloudAMQP</h2>
          </div>
          <div className="diplom-footer">
            <div className="row">
              <div className="column-image col order-2 d-flex justify-content-center">
                <div className="img-holder">
                  <img src="/images/badge.svg" width="231" height="326" alt=""></img>
                </div>
              </div>
              <div className="col order-1">
                <div className="footer-block">
                  <div className="holder">
                    <p>{user.certificate}</p>
                  </div>
                  <div className="footer">
                    <strong className="subtitle">Date</strong>
                  </div>
                </div>
              </div>
              <div className="col order-3">
                <div className="footer-block">
                  <div className="holder">
                    <img src="/images/logo-84codes.svg" width="154" height="56" alt="84 codes"></img>
                  </div>
                  <div className="footer">
                    <strong className="subtitle">Signature</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="diplom_Corner_left"></span>
        <span className="diplom_Corner_right"></span>
      </div>
    )
  } else {
    return null
  }
}

export default Certificate
