import React, { useState, useContext, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { SectionContext } from '../contexts/SectionContext'
import { UserContext } from '../contexts/UserContext'
import { ProgressContext } from '../contexts/ProgressContext'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { stackoverflowDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Quiz from 'react-quiz-component'
import { useBeforeunload } from 'react-beforeunload'
import { SendCertificate } from './User'
import { Examination } from '../quiz/Examination'

function ContentLoader ({ done, passed }) {
  const [sectionState, ] = useContext(SectionContext)
  const [content, setContent] = useState(null)
  const [user, dispatchUser] = useContext(UserContext)
  const [progress, dispatchProgress] = useContext(ProgressContext)
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [quizDone, setQuizDone] = useState(false)
  const [certificateSent, setCertificateSent] = useState(false)
  const examPercentage = 0.85
  const feedback_message = {
    exchange: <ExchangeFeedback/>,
    delivery_mode: <DeliveryModeFeedback/>,
    body: <BodyFeedback/>,
  }
  useBeforeunload(event => beforeUnload(event))

  useEffect(() => {
    fetchContent()
  }, [sectionState, user])

  function beforeUnload(event) {
    if ((sectionState.subsection.content === 'theoretical_exam' || sectionState.subsection.content === 'quiz') && !quizDone) {
      event.preventDefault()
    }
  }

  async function fetchContent () {
    const id = sectionState.subsection.id
    if (sectionState.subsection.content === 'quiz') {
      const quiz = await import(`../quiz/Quiz${id}`)
      setContent(quiz.Quiz)
    } else if (sectionState.subsection.content === 'theoretical_exam') {
      setContent(Examination)
    } else if (sectionState.subsection.content === 'practical_exam') {
      if (progress.progress.includes(id - 1)) {
        setContent(exam)
      } else {
        setContent(examNotPassed)
      }
    } else {
      const file = await import(`../lectures/Lecture${id}.md`)
      const response = await fetch(file.default)
      setContent(await response.text())
    }
  }

  function quizFinished(result) {
    if (result.numberOfCorrectAnswers >= examPercentage*result.numberOfQuestions) {
      passed(true)
      dispatchProgress({ type: 'AddProgress', subsection: sectionState.subsection })
    } else {
      passed(false)
    }
    setQuizDone(true)
    done(true)
  }

  function fetchCertificate () {
    SendCertificate().then(() => {
      setCertificateSent(true)
    })
  }

  function requestApproval () {
    dispatchUser({ type: 'SetPracticalExam', done: true })
    window.location.reload(true)
  }

  function ExamFeedback () {
    return (
      <div>
        <h1>Your practical exam could not be approved</h1>
        <h3>Here are some feedback: </h3>
        <ul>
          {user.exam_feedback.map((feedback) => {
            return feedback_message[feedback]
          })}
        </ul>
      </div>
    )
  }

  function ExchangeFeedback () {
    return (
      <li>You need to declare your own exchange and binding. The name of the exchange needs to be <br/><code>exchange.{user.id}</code></li>
    )
  }

  function DeliveryModeFeedback () {
    return (
      <li> The message you sent was not <b>persistent</b>. More info about persistent messages can be found in <a href="https://www.cloudamqp.com/blog/faq-what-is-the-delivery-mode-in-amqp.html">this CloudAMQP blog post</a></li>
    )
  }

  function BodyFeedback () {
    return (
      <li>Your message did not include the text: <code>Hi CloudAMQP, this was fun!</code></li>
    )
  }

  function Markdown () {
    return (
      <ReactMarkdown
        children={content}
        rehypePlugins={[rehypeRaw]}
        components={{
          code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={stackoverflowDark}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          }
        }}
      />
    )
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  function linkedInRedirect() {
    const date = new Date(user.certificate)
    openInNewTab(`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Free%20RabbitMQ%20Training%20Course%20-%20by%20CloudAMQP&organizationId=16256377&issueYear=${date.getFullYear()}&issueMonth=${date.getMonth() + 1}&certUrl=https%3A%2F%2Ftraining.cloudamqp.com`)
  }

  function CertificateSent () {
    return (
      <div>
        <br/>
        <p>Great! The certificate has been sent to your email.</p>
      </div>
    )
  }

  const exam = `# Practical Exam
  It is now time for the final part of the examination — a practical exam according to the instructions below. When you've completed the task and requested an approval, your examination will be evaluated immediately and then you will able to get your certificate. Since the evaluation will be done automatically, it's important that you follow the instructions carefully.

  **Good luck!**

  ## Instructions
  Connect to the RabbitMQ server with:<br><code>amqps://student:XYR4yqc.cxh4zug6vje@rabbitmq-exam.rmq3.cloudamqp.com/mxifnklj</code>

  On this broker, we have created a queue, called **exam**.

  Your task is to send a message with the payload <code>Hi CloudAMQP, this was fun!</code> to the queue **exam**.

  The message needs to be a **persistent** message and make use of the routing key: <code>${user.id}</code>.

  Any exchange you create must be called <code>exchange.${user.id}</code>. You are not allowed to use one of the pre-declared exchanges, such as amq.topic.

  **Important!** When the message is sent, you need to make sure the exchanges and bindings you have created are deleted. Also, that you close the connection to the server.
  `

  const examNotPassed = `# Practical Exam
  You have to pass the theoretical exam before getting access to the practical exam.
  `

  if (content) {
    if (sectionState.subsection.content === 'quiz' || sectionState.subsection.content === 'theoretical_exam') {
      return (
        <>
          <h1>{content.quizTitle}</h1>
          <Quiz quiz={content} shuffle={true} showInstantFeedback={true} onComplete={quizFinished} />
        </>
      )
    } else if (sectionState.subsection.content === 'practical_exam' && !user.name && progress.progress.includes(sectionState.subsection.id-1)) {
      return (
        <div className='name-form'>
          <h1>Great! You passed the theoretical exam!</h1>
          <p>The first part of your examination is completed and now it's time for the practical exam. But before moving along to the second part, we need your name for the certificate.</p>
          <h2>Enter your name</h2>
          <p className='form-text-field'>
            <label htmlFor='cert-first-name'>First name</label>
            <input type='text' name='first-name' id='cert-first-name' value={name} required onChange={e => setName(e.target.value)} />
          </p>
          <p className='form-text-field'>
            <label htmlFor='cert-surname'>Surname</label>
            <input type='text' name='surname' id='cert-surname' value={surname} required onChange={e => setSurname(e.target.value)} />
          </p>
          <p className='form-submit-field'>
            <button className='btn btn-warning mt-4' onClick={() => dispatchUser({ type: 'AddName', name, surname })}>Register name</button>
          </p>
        </div>
      )
    } else if (sectionState.subsection.content === 'practical_exam' && progress.progress.includes(sectionState.subsection.id-1) && progress.progress.includes(sectionState.subsection.id)) {
      return (
        <div>
          <h1>Congratulations! You passed the examination!</h1>
          <p>The CloudAMQP team have now approved your practical exam, which means you've earned a certificate of completion in this RabbitMQ Training Course. If you press the button below you will get an email and you'll be able to download your certificate.</p>
          <p>We hope you've enjoyed the course and that you found it useful. Feel free to leave a rating at the top of the page if you haven't done so already.</p>
          <button className='btn btn-info mt-4 mb-4 d-block' onClick={() => linkedInRedirect()}>
          <svg width="17" height="18" class="me-2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.438 1H1.15C.52 1 0 1.557 0 2.225v14.213c0 .668.52 1.187 1.15 1.187h14.287c.631 0 1.188-.52 1.188-1.188V2.226c0-.668-.557-1.225-1.188-1.225ZM5.01 15.25H2.56V7.346h2.45v7.904ZM3.785 6.232c-.816 0-1.447-.63-1.447-1.41 0-.779.63-1.447 1.447-1.447.78 0 1.41.668 1.41 1.447 0 .78-.63 1.41-1.41 1.41ZM14.25 15.25h-2.486v-3.86c0-.89 0-2.078-1.262-2.078-1.299 0-1.484 1.003-1.484 2.042v3.896h-2.45V7.346h2.338v1.076h.037c.334-.631 1.15-1.299 2.338-1.299 2.487 0 2.969 1.67 2.969 3.785v4.342Z" fill="#fff"/></svg>
          Add to LinkedIn</button>
          <button className='btn btn-warning mt-4 d-block' onClick={() => fetchCertificate()}>Get your certificate</button>
          {certificateSent ? <CertificateSent/> : null}
        </div>
      )
    } else if (sectionState.subsection.content === 'practical_exam' && user.waiting_for_exam_approval) {
      if (user.exam_feedback.length === 0) {
        return (
          <div>
            <h1>Your practical exam has yet to be approved</h1>
            <p>It looks like your practical exam is taking a while to approve. This may mean that it was not completed correctly or simply that there is a minor delay in the automatic approval process.</p>
            <p>Wait a few seconds and then reload the page to see if the exam has been approved. If you're still stuck on this page, make sure you have completed the exam correctly.</p>
            <p>Don't forget that the routing key you use to publish the message needs to be <code>{user.id}</code>.</p>
            <p>Press the button below to get back to the practical examination instructions.</p>
            <button className='btn btn-warning mt-4' onClick={() => dispatchUser({ type: 'SetPracticalExam', done: false })}>Back to instructions</button>
          </div>
        )
      } else {
        return (
          <div>
            <ExamFeedback/>
            <button className='btn btn-warning mt-4' onClick={() => dispatchUser({ type: 'SetPracticalExam', done: false })}>Back to instructions</button>
          </div>
        )
      }
    } else if (sectionState.subsection.content === 'practical_exam' && progress.progress.includes(sectionState.subsection.id-1)) {
      return (
        <div>
          <Markdown/>
          <button className='btn btn-warning mt-4' onClick={() => requestApproval()}>Request approval</button>
        </div>
      )
    } else {
      return (
        <Markdown/>
      )
    }
  } else {
    return null
  }
}

export default ContentLoader
