import React, { useState, useContext, useEffect } from 'react'
import { User } from './User'
import { UserContext } from '../contexts/UserContext'
import { SignInContext } from '../contexts/SignInContext'
import { ProgressContext } from '../contexts/ProgressContext'
import { RatingContext } from '../contexts/RatingContext'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { Subsections } from './Sections'

function SignIn () {
  const [user, dispatchUser] = useContext(UserContext)
  const [, dispatchProgress] = useContext(ProgressContext)
  const [email, setEmail] = useState()
  const [consentToTrack, setConsentToTrack] = useState(true)
  const [signIn, setSignIn] = useContext(SignInContext)
  const [signInReady, setSignInReady] = useState(false)
  const [, setRating] = useContext(RatingContext)
  const emailRegex = require('email-regex')
  const history = useHistory()
  const cookies = new Cookies()

  useEffect(() => {
    setUser()
  }, [user, cookies])

  function setUser() {
    const userCookie = cookies.get('user')
    if (userCookie) {
      User(userCookie.email, null).then(user => {
        if (user && user.email) {
          dispatchUser({ type: 'SetUser', user })
          if (user.rating) {
            setRating(state => ({ ...state, userRating: user.rating }))
          }
          if (user.progress) {
            dispatchProgress({ type: 'SetUserProgress', user })
          }
        }
        history.push('/course/' + Subsections[0].id)
      })
    }
  }

  const handleSignIn = (event) => {
    event.preventDefault()
    event.stopPropagation()
    User(email, consentToTrack).then(user => {
      if (user && user.email) {
        dispatchUser({ type: 'SetUser', user })
        if (user.rating) {
          setRating(state => ({ ...state, userRating: user.rating }))
        }
        if (user.progress) {
          dispatchProgress({ type: 'SetUserProgress', user })
        }
      }
      setSignIn(false)
    })
  }

  function validateEmail (email) {
    if (emailRegex({ exact: true }).test(email)) {
      setSignInReady(true)
    } else {
      setSignInReady(false)
    }
    setEmail(email)
  }

  const handleCheckboxChange = (event) => {
    setConsentToTrack(event.target.checked)
  }

  return (
    <Modal className='signup-holder' dialogClassName='signup-modal' show={signIn} onHide={() => setSignIn(false)}>
      <Modal.Header closeButton>
        <Modal.Title className='mb-0'>Let's get to it...</Modal.Title>
      </Modal.Header>
      <Modal.Body className='mb-3'>
        <h2 className='mb-2'> Great To Have You Here!</h2>
        <p>Welcome to our RabbitMQ training, where we have set out to build a course that provides a good foundation for RabbitMQ.</p>
        <p>We want to keep it simple, so we ditched the password. You just need to enter an email. This will help you track your progress, receive email updates and, after being examined in the course, receive a certificate.</p>
        <p>We pledge to provide this training for free, forever. By entering this course you accept our <a href='https://www.cloudamqp.com/privacy_policy.html' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>. Important content: no spam, no sharing to third-parties. Good luck &amp; have fun!</p>
        <p><em>/CloudAMQP team</em></p>
        <Form className='form-group d-grid gap-3' noValidate onSubmit={handleSignIn}>
          <Form.Group controlId='formEmail'>
            <Form.Label><strong>Email Address</strong></Form.Label>
            <Form.Control type='email' name='email' required autoComplete='true' onChange={e => validateEmail(e.target.value)} />
          </Form.Group>
          <Form.Group controlId='formCheckbox'>
            <Form.Check type='checkbox'>
              <Form.Check.Input type='checkbox' checked={consentToTrack} onChange={handleCheckboxChange}/>
              <Form.Check.Label>{'I want to receive relevant information about the course and CloudAMQP'}</Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Button className='btn btn-warning' disabled={!signInReady} type="submit">
            Go to lectures
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default SignIn
