import React from 'react'

function Footer () {
  const currentYear = new Date().getFullYear()

  return (
    <footer id='footer'>
      <div className='container'>
        <div className='row justify-content-md-between align-items-center mb-2 mb-md-4'>
          <div className='col-12 col-md-6'>
            <div className='brand-wrap'><div className='navbar-brand'><img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt='RabbitMQ Training logo' width='29' height='41' /></div><span className='slogan d-none d-lg-block'>Free RabbitMQ Training Course - <a href='https://cloudamqp.com' target='_blank' rel='noopener noreferrer'>by CloudAMQP</a></span></div>
          </div>
          <div className='col-12 col-md-6 text-md-end'><span className='copy'>Copyright &copy; {currentYear} CloudAMQP</span></div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <nav className='add-nav'>
              <ul>
                <li><a href='https://www.cloudamqp.com/privacy_policy.html'>Privacy Policy</a></li>
                <li><a href='https://www.cloudamqp.com/gdpr.html'>GDPR</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
